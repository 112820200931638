import React, { createRef, useCallback, useState } from "react";
import { BASE_WEB_URL } from "../url";
import styles from "./UploadWidget.module.css";

const UploadWidget: React.FC<{}> = () => {
  const fileRef = createRef<HTMLInputElement>();
  const [progress, setProgress] = useState(NaN);
  const onUpload = useCallback(async () => {
    if (
      fileRef.current &&
      fileRef.current.files &&
      fileRef.current.files.length
    ) {
      const formData = new FormData();
      formData.append("fn", fileRef.current.files[0]);

      const xhr = new XMLHttpRequest();

      xhr.onload = () => {
        const json = JSON.parse(xhr.response);

        (window.top || window).location.href = `${BASE_WEB_URL}/f/${json.link_hash}#created`
      };

      xhr.upload.onprogress = (e) => {
        setProgress(e.loaded / e.total);
      };

      xhr.open("POST", `${process.env.REACT_APP_SERVER_URL}/upload`);
      xhr.send(formData);
    }
  }, [fileRef]);
  return (
    <div className={styles.widget}>
      {!isNaN(progress) && <div>Uploading: {progress * 100}%</div>}
      <input type="file" ref={fileRef} />
      <button onClick={onUpload}>Upload</button>
    </div>
  );
};

export default UploadWidget;
