import React, { useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import PdfDocument from "./pdf_document";
import * as pdfjs from "pdfjs-dist";
import {
  onClientLeave,
  onInitialAnnotations,
  onNewClient,
  selectAuth,
  selectUsers,
  setName,
  zoomIn,
  zoomOut,
} from "./slice";
import TopBar from "./topbar";
import ReactModal from "react-modal";
import { clearMousePosition, socket } from "../sync_manager";
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";

import "pdfjs-dist/web/pdf_viewer.css";
import { BASE_WEB_URL } from "../url";

enum ModalState {
  UNKNOWN = 0,
  GUEST = 1,
  CREATOR = 2,
}
const Modal: React.FC<{ docid: string; filename: string }> = ({
  docid,
  filename,
}) => {
  const [modalState, setModalState] = useState<ModalState>(ModalState.UNKNOWN);
  useEffect(() => {
    if (window.location.hash === "#created") {
      window.location.hash = "";
      setModalState(ModalState.CREATOR);
    } else {
      setModalState(ModalState.GUEST);
    }
  }, [setModalState]);
  return (
    <ReactModal isOpen>
      {modalState === ModalState.CREATOR && <CreatorModal docid={docid} />}
      {modalState === ModalState.GUEST && <GuestModal filename={filename} />}
    </ReactModal>
  );
};

const CopyButton: React.FC<{ value: string }> = React.memo(({ value }) => {
  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(value).then(() => setCopied(true));
  }, [value]);
  return <button onClick={onCopy}>{copied ? "Copied" : "Copy"}</button>;
});

const CreatorModal: React.FC<{ docid: string }> = ({ docid }) => {
  const dispatch = useAppDispatch();
  const [nameInput, setNameInput] = useState("");
  const link = `${BASE_WEB_URL}/f/${docid}`;
  return (
    <>
      <h1>Your document is now available!</h1>
      <p>Use the following link to share</p>
      <p>
        <input value={link} readOnly />
        <CopyButton value={link} />
      </p>
      What's your name?
      <input
        value={nameInput}
        onChange={(e) => setNameInput(e.currentTarget.value)}
      />
      <button
        disabled={!nameInput}
        onClick={() => dispatch(setName({ name: nameInput }))}
      >
        Submit
      </button>
    </>
  );
};

const GuestModal: React.FC<{ filename: string }> = ({ filename }) => {
  const dispatch = useAppDispatch();
  const [nameInput, setNameInput] = useState("");
  return (
    <>
      <h1>You are invited to collaborate on {filename}</h1>
      <p>Please provide the information below</p>
      <p>
        <label htmlFor="guest-modal-name">Name</label>
      </p>
      <p>
        <input
          id="guest-modal-name"
          value={nameInput}
          onChange={(e) => setNameInput(e.currentTarget.value)}
        />
      </p>
      <p>Your name will be shared with your collaborators.</p>
      <p>
        <button
          disabled={!nameInput}
          onClick={() => dispatch(setName({ name: nameInput }))}
        >
          Submit
        </button>
      </p>
    </>
  );
};

const ConnectionManager: React.FC<{ docid: string }> = React.memo(
  ({ docid }) => {
    const auth = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (auth) {
        socket.auth = { ...auth, docid };
        socket.connect();
        socket.emit("startsession");

        socket.on("newclient", (clients) => {
          for (const client of clients) {
            dispatch(
              onNewClient({
                connectionId: client.origin,
                name: client.name,
              })
            );
          }
        });

        socket.on("initialannotations", (annotations) => {
          dispatch(onInitialAnnotations({ annotations }));
        });

        socket.on("clientleave", (clients) => {
          for (const client of clients) {
            dispatch(
              onClientLeave({
                connectionId: client.origin,
              })
            );
          }
        });
      }

      const visibilityChange = function () {
        if (document.visibilityState === "hidden") {
          clearMousePosition();
        }
      };

      document.addEventListener("visibilitychange", visibilityChange);

      return () => {
        socket.disconnect();
        document.removeEventListener("visibilitychange", visibilityChange);
      };
    }, [auth, dispatch, docid]);

    return null;
  }
);

const Toolbar: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <button onClick={() => dispatch(zoomOut())}>-</button>
      <button onClick={() => dispatch(zoomIn())}>+</button>
    </div>
  );
};

const ViewerApp: React.FC<{
  id: string;
  filename: string;
  url: string;
}> = ({ id, url, filename }) => {
  const users = useAppSelector(selectUsers);
  const auth = useAppSelector(selectAuth);

  const [pdf, setPdf] = useState<PDFDocumentProxy | undefined>();
  const [progress, setProgress] = useState<number | undefined>();

  useEffect(() => {
    const task = pdfjs.getDocument(url);

    task.promise.then((val) => {
      setPdf(val);
    });

    task.onProgress = (status: { loaded: number; total: number }) => {
      setProgress(status.loaded / status.total);
    };

    return () => {
      task.destroy();

      socket.disconnect();
    };
  }, [setPdf, url]);

  return (
    <div>
      <ConnectionManager docid={id} />
      {auth ? null : <Modal filename={filename} docid={id} />}
      <TopBar users={users} filename={filename} pdf={pdf} />

      {pdf ? (
        <div>
          <Toolbar />
          <PdfDocument pdf={pdf} />
        </div>
      ) : (
        <div>{progress}</div>
      )}
    </div>
  );
};

export default ViewerApp;
