import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import React, { useCallback } from "react";
import { useAppSelector } from "../app/hooks";
import { selectAnnotationStorage, User } from "./slice";
import style from "./topbar.module.css";
import { saveAs } from "file-saver";

function initial(name: string): string {
  const parts = name.split(/\s+/);
  if (parts.length > 1) {
    return (
      parts[0].charAt(0).toLocaleUpperCase() +
      parts[parts.length - 1].charAt(0).toLocaleUpperCase()
    );
  }
  return parts[0].charAt(0).toLocaleUpperCase();
}

const TopBar: React.FC<{ users: User[]; pdf?: PDFDocumentProxy; filename: string }> = ({
  filename,
  users,
  pdf,
}) => {
  const annotationStorage = useAppSelector(selectAnnotationStorage);

  const save = useCallback(async () => {
    if (pdf && annotationStorage) {
      // wrong type definition in pdfjs
      const data = await pdf.saveDocument(annotationStorage as any);
      saveAs(new Blob([data]), filename);
    }
  }, [pdf, annotationStorage, filename]);

  return (
    <div className={style.topBar}>
      <div className={style.left}>{filename}</div>
      <div>
        <ul className={style.userList}>
          {users.map((user, index) => (
            <li
              key={user.id}
              className={style.userIcon}
              style={{ zIndex: 999-index, backgroundColor: user.color }}
            >
              {initial(user.name)}
            </li>
          ))}
        </ul>
      </div>
      <div>

      <button onClick={save}>Save</button>
      </div>
    </div>
  );
};

export default TopBar;
